import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./middle.scss";
import { useNavigate } from "react-router-dom";
import data1 from "../../../global/projects.json";

const Card = ({ targetFeaturedProjects }) => {
  const navigate = useNavigate();

  let data = shuffleArray(data1);

  data = data.slice(0, 6);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
  }
  const handleClick = (item) => {
    navigate(`/projects/interest/${item.routeHelper}`);
    console.log(item.title);
  };
  return (
    <>
      <Box sx={{ padding: "0px 10px" }}>
        <Typography
          ref={targetFeaturedProjects}
          className="header-middle-project"
          style={{ scrollMarginTop: "80px" }}
        >
          <span style={{ color: "black" }}>Featured </span> Projects
        </Typography>
        <Typography className="normal-middle-project">
          Find interesting projects and get started with an execution plan
        </Typography>
        <Box className="top-wrapper-middle-project">
          {data.map((item) => (
            <Box key={item.id} className="card-wrapper-middle-project">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="5px" alignItems="center"></Box>
              </Box>
              <Typography className="header-text-middle-project">
                {item.title}
              </Typography>
              <Typography className="normal-text-middle-project">
                {item.objective}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                sx={{
                  flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
                  alignItems: { xs: "flex-start", sm: "center", md: "center", lg: "center" }
                }}
              >
                {/* <Box sx={{height:{xs:2}}}></Box>  for adding img */}
                <Box>
                  <Typography className="prerequisitesH-middle-project">
                    Prerequisites
                  </Typography>
                  <Typography className="prerequisites-middle-project">
                    {item.prerequisites.join(", ")}
                  </Typography>
                </Box>
                <Button
                  sx={{ mt: { xs: 10 }, width: { xs: "100%", sm: "auto" } }} // Set width to 100% for xs
                  onClick={() => {
                    handleClick(item);
                  }}
                  className="btn-middle-project"
                >
                  Interested
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

    </>
  );
};

export default Card;
