import React from 'react';
import './PreLoader.css';
import logo from '../../../assets/images/purple_logo.svg';

const Preloader = () => {
  return (
    <div className="preloader-container">
      <div className="progress-circle">
        <svg className="logo-svg rotating-progress" viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="35"  // Radius of the circle
            stroke="#ddd"
            strokeWidth="7"
            fill="none"
          />
          <circle
            cx="50"
            cy="50"
            r="35"  // Same radius for the progress circle
            stroke="#7234F7"
            strokeWidth="7"
            strokeDasharray="40, 157"  // Show 40 units of the stroke, leave a gap of 157
            strokeDashoffset="0"       // Starts the stroke from the top of the circle
            fill="none"
            strokeLinecap = "round"
          />
        </svg>
        <img src={logo} alt="Logo" className="logo-image" />
      </div>
    </div>
  );
};

export default Preloader;
