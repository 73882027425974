import "../subscribe/Subscribe.scss";
import { Box, Typography, TextField, Button } from "@mui/material";
import bookImg from "../../../assets/images/subscribe/book.svg";
import circles from "../../../assets/images/subscribe/circle.svg";
import yes from "../../../assets/images/subscribe/Yes.svg";
import { useState } from "react";
import axios from 'axios';

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    setLoading(true);
    if (!validateEmail(email)) {
      setSuccessMessage('Please enter a valid email address');
      setLoading(false);
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_LINK}/api/subscribe`, { email: email });
      setEmail('');
      setSuccessMessage('You have been subscribed successfully!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000); 
    } catch (error) {
      console.error('Error subscribing:', error.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: { xs: "24px 16px", md: "60px 32px" },backgroundColor:"#FFFFFF" }}>
      <Box
        className="subscribeWrapper"
        sx={{ p: { xs: "20px 16px 20px 16px", md: "22px 100px" } ,borderRadius:{md:"23px",xs:"9px"},  }}
      >
        <Box
          className="subscribeContent"
          sx={{ pt: { sm: "20px", md: "37px" } }}
        >
          <Typography
            className="tagLine"
            sx={{
              fontSize: { xs: "16px", md: "32px" },
              lineHeight: { xs: "18px", md: "42px" },
            }}
          >
            Knowledge is your passport to success. Subscribe to our Newsletter today!
          </Typography>
          <Box className="subscribeForm">
            <TextField
              label="Email Address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: { xs: "116px", md: "273px" },
                borderRadius: "3px",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#FFFFFF",
                  height: { xs: "26px", md: "48px" },
                  mb: "7px",
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "#B4B4B4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", 
                  },
                },
                "& .MuiOutlinedInput-input": {
                  color: "#000000",
                  fontSize: { xs: "10px", md: "16px" },
                  lineHeight: "normal",
                  padding: { md: "12px" },
                },
                "& .MuiInputLabel-outlined": {
                  color: "#B4B4B4",
                  fontSize: { xs: "10px", md: "16px" },
                  lineHeight: "normal",
                  transform: {
                    xs: "translate(7px, 6px) scale(1)",
                    md: "translate(14px, 16px) scale(1)",
                  },
                },
                "& .MuiInputLabel-outlined.Mui-focused, & .MuiInputLabel-outlined.Mui-shrink": {
                  color:"white !important",
                  transform: {
                    xs: "translate(7px, -10px) scale(0.75)",
                    md: "translate(14px, -20px) scale(0.75)",
                  },
                },
              }}
            />
            <Button
              className="subscribeBtn"
              sx={{
                pt: "10px",
                width: { xs: "68px", md: "auto" },
                height: { xs: "26px", md: "48px" },
                fontSize: { xs: "12px", md: "16px" },
              }}
              onClick={handleSubscribe}
              disabled={loading}
            >
              {loading ? "Loading..." : "Subscribe"}
            </Button>
          </Box>
          {successMessage && (
            <Box
              className="successMessage"
              sx={{
                color: "#02da15",
                display:"flex",
                alignItems:"center",
                fontSize: { xs: "8px", md: "12px" },
              }}
            >
             <Box component="img" sx={{pr:"2px"}}src={yes}/> {successMessage}
            </Box>
          )}
        </Box>
        <Box component="img" src={bookImg} sx={{ width: "30%" }} />
        <Box
          component="img"
          src={circles}
          alt="Circles"
          sx={{
            position: "absolute",
            top: "50%",
            left: "0px",
            transform: "translateY(-50%)",
            visibility: { xs: "hidden", md: "visible" },
          }}
        />
      </Box>
    </Box>
  );
};

export default Subscribe;