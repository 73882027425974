import { Box, Button, Typography } from "@mui/material";
import "../workshops/Workshops.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useRef, useEffect } from "react";
import bigdatawelfare from '../../../assets/images/workshopcards/big-data-welfare.png'
import carerinds from '../../../assets/images/workshopcards/career_in_ds.png'
import datajourneysqltableau from '../../../assets/images/workshopcards/data-journey-sql-tableau.png'
import dsinfinance from '../../../assets/images/workshopcards/data-science-in-finance.png'
import ds from "../../../assets/images/workshopcards/data-science.png"
import dm from "../../../assets/images/workshopcards/digital-marketing.png"
import eform from "../../../assets/images/workshopcards/excel-for-data-analytics.png"
import glooker from "../../../assets/images/workshopcards/google-looker.png"
import higheduds from "../../../assets/images/workshopcards/higher-edu-in-ds.png"
import jsmastery from "../../../assets/images/workshopcards/js-mastery.png"
import py from "../../../assets/images/workshopcards/python.png"
import sql from "../../../assets/images/workshopcards/sql-tableau.png"
import ad from "../../../assets/images/workshopcards/android.png"
import mlops from "../../../assets/images/workshopcards/mlops.png"
import aichat from "../../../assets/images/workshopcards/ai.png"
import aws from "../../../assets/images/workshopcards/aws.png"
import frontend from "../../../assets/images/workshopcards/frontend.png"
import genai from "../../../assets/images/workshopcards/gen_AI.png"
import dsa from "../../../assets/images/workshopcards/dsa.png"
import flutter from "../../../assets/images/workshopcards/flutter.png"
import cysec from "../../../assets/images/workshopcards/cybersec.png"
import mern from "../../../assets/images/workshopcards/mern.png"
import pm from "../../../assets/images/workshopcards/performancemarketing.png"
import accacma from "../../../assets/images/workshopcards/accacma.png"

import LSarrow from "../../../assets/images/workshop/left_secondary_arrow.svg"
import RSarrow from "../../../assets/images/workshop/right_secondary_arrow.svg"
import RParrow from "../../../assets/images/workshop/right_primary_arrow.svg"
import LParrow from "../../../assets/images/workshop/left_primary_arrow.svg"

import "../workshops/Workshops.scss";
import WorkshopCard from "../../workshop/worshopcard/worshopcard";


const allContent = [
  {
    bannerImg: flutter,
    workshop: "5 Day Flutter Bootcamp",
    mentor: "",
    date: "23rd-27th Sep, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/flutter-app-development",
    gif: "red",
    status: 'Recorded session',
  }
  ,

  {
    bannerImg: dm,
    workshop: "Digital marketing",
    mentor: "Dr. Ashutosh",
    date: "1st June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/digital-marketing-masterclass",
    gif: "red",
    status: 'Recorded session',
  },
  {
    bannerImg: carerinds,
    workshop: "How to start your career in Data science",
    mentor: "Nikanskha Jain",
    date: "24th-25th August, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/career-in-data-science",
    gif: "red",
    status: 'Recorded session',
  },
  {
    bannerImg: bigdatawelfare,
    workshop: "Big data to big welfare",
    mentor: "Revanth Guthala",
    date: "27th April, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/bigdata-to-bigwelfare",
    gif: "red",
    status: 'Recorded session',
  },
  {
    bannerImg: py,
    workshop: "Python for Data Analytics",
    mentor: "Nikansha Jain",
    date: "24th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/python-data-analytics",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: carerinds,
    workshop: "How to start your career in Data science",
    mentor: "Nikanskha Jain",
    date: "24th May, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/career-in-data-science",
    gif: "red",
    status: 'Recorded session',
  },


  {
    bannerImg: jsmastery,
    workshop: "Free JavaScript mastery",
    mentor: "Neha Gupta",
    date: "15th-20th April, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/javascript-mastery",
    gif: "red",
    status: 'Recorded session',
  },
  {
    bannerImg: ad,
    workshop: "2 Day Android Development",
    mentor: "",
    date: "21st-22nd Sep, 2024",
    button: "Know more",
    status: 'Recorded session',
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZUodeGupjwvHN2HrhtrkssxwfMlTs7JNZyz",
    gif: "red",
  },
  {
    bannerImg: dsinfinance,
    workshop: "Data science in finance & banking",
    mentor: "Neha Gupta",
    date: "18th-19th April, 2024",
    button: "Know more",
    status: 'Recorded session',
    btnLink:
      "http://upskill.coursevita.com/data-science-masterclass",
    gif: "red",
  },
  {
    bannerImg: mlops,
    workshop: "5 Day - MLOPS Course",
    mentor: "Bharat Sreeram",
    date: "18th-21st Sep, 2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZElcOGsrDgqG9CmNeDsWzMY2LEzot5WS3ID",
    gif: "red",
    status: 'Recorded session',
  },
  {
    bannerImg: aichat,
    workshop: "AI Chatbot Development Workshop",
    mentor: "Revanth GH",
    date: "16th-20th Sep, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/ai-chatbot-development",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: aws,
    workshop: "Master AWS Devops",
    mentor: "Vinayak Ojha",
    date: "2nd-18th Sep, 2024",
    button: "Register Now",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZMuf-qpqjorHdS9_mK10Dw2c3Bl3jWc_gd1",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: frontend,
    workshop: "25 Day Frontend Course",
    mentor: "Narsimha",
    date: "2nd-28th Sep, 2024",
    button: "Register Now",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZcqcumoqjwrHN1HQzp96UWaUvQckDu0WKDQ",
    gif: "red",

    status: 'Recorded session',
  },

  {
    bannerImg: genai,
    workshop: "Build your customized chat bot using GEN AI",
    mentor: "Revanth Guthala",
    date: "25th August, 2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZcvc-muqDIiEtCdnj8zm1Zm9I7S1TrK-LUR#/registration",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: carerinds,
    workshop: "How to start your career in Data science?",
    mentor: "Nikanskha Jain",
    date: "24th-25th August, 2024",
    button: "Know more",
    btnLink:
      "https://us06web.zoom.us/meeting/register/tZ0pcOqoqzIpHNU2G4BOCTUKgM-MtvA2kWrY",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: eform,
    workshop: "Excel For Data Analytics",
    mentor: "Viswaja Reddy",
    date: "20th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/excel-for-dataanalytics",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: py,
    workshop: "25 Day Python Course",
    mentor: "Atharv",
    date: "9th Sep-11th Oct, 2024",
    button: "Know more",
    btnLink: "https://us06web.zoom.us/meeting/register/tZElc-mtrDMuHtwGTQ7Dh1iuU_RS26YYb_rX",
    gif: "red",

    status: 'Recorded session',
  },

  {
    bannerImg: glooker,
    workshop: "5 Day Google’s Looker Studio Cohort",
    mentor: "Hemanth Guthala",
    date: "5th-9th July, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/looker-studio-cohort",
    gif: "red",

    status: 'Recorded session',
  },

  {
    bannerImg: higheduds,
    workshop: "Higher Education in Data science with data AI & ML",
    mentor: "Dr. Sanatan Sukhija",
    date: "2nd June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/data-science-workshop",
    gif: "red",

    status: 'Recorded session',
  },
  {
    bannerImg: datajourneysqltableau,
    workshop: "Data journey with SQL & Tableau",
    mentor: "Baldev Bhat",
    date: "19th-21st June, 2024",
    button: "Know more",
    btnLink: "http://upskill.coursevita.com/data-viz-workshop",
    gif: "red",

    status: 'Recorded session',
  },
  // recheckfromhere
  {
    bannerImg: frontend,
    workshop: "10 Day Frontend Course",
    mentor: "Narsimha",
    date: "2-Sep-2024",
    time: "7:00 PM to 8:30 PM",
    button: "Know more",
    btnLink: "https://www.youtube.com/playlist?list=PLAxw0KOXGLQqqw7nkXm_3udLyDwKzZC84",
    gif: "red",
    status: "Recorded session"
  },
  {
    bannerImg: aws,
    workshop: "10 Day AWS Bootcamp",
    mentor: "Vinayak Ojha",
    date: "2-Sep-2024",
    time: "7:00 AM to 8:30 AM",
    button: "Know more",
    btnLink: "https://www.youtube.com/playlist?list=PLAxw0KOXGLQpk9I45EMTmmJki_o6lEZZR",
    gif: "red",
    status: "Recorded session"
  },
  {
    bannerImg: py,
    workshop: "5 Day Python Course",
    mentor: "Siva Mohan",
    date: "9th-13th Sep, 2024",
    button: "Know more",
    btnLink: "https://www.youtube.com/playlist?list=PLAxw0KOXGLQr9JXrzpYeyFa5Dgc7xliW7",
    gif: "red",
    status: "Recorded session"
  },
  {
    bannerImg: mlops,
    workshop: "5 Day MLOps Course",
    mentor: "Bharat Sreeram",
    date: "18th-21st Sep, 2024",
    button: "Know more",
    btnLink: "https://us06web.zoom.us/meeting/register/tZElcOGsrDgqG9CmNeDsWzMY2LEzot5WS3ID",
    gif: "red",
    status: "Recorded session"
  },
  {
    bannerImg: aichat,
    workshop: "AI Chatbot Development Workshop",
    mentor: "Revanth",
    date: "16th-20th Sep, 2024",
    button: "Know more",
    btnLink: "https://upskill.coursevita.com/ai-chatbot-development",
    gif: "red",
    status: "Recoreded session"
  },
  {
    bannerImg: dsa,
    workshop: "5 Day DSA Course",
    mentor: "",
    date: "23rd-27th Sep, 2024",
    button: "Know more",
    btnLink: "",
    gif: "red",
    status: "Recorded session"
  },
  {
    bannerImg: flutter,
    workshop: "10 Day Flutter Bootcamp",
    mentor: "Narayan Vyas",
    date: "13th-22nd Oct, 2024",
    button: "Register Now",

    btnLink: "https://upskill.coursevita.com/flutter-app-development",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: frontend,
    workshop: "Full Stack 5 Day Project",
    mentor: "Satya Deep G",
    date: "7th-11th Oct, 2024",
    button: "Register Now",

    btnLink: "https://us06web.zoom.us/meeting/register/tZIofuGppjwoHNRNIkY2H_kj5RhkO5BLReGL",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: accacma,
    workshop: "ACCA/CMA Workshop",
    mentor: "Deeksha",
    date: "19th-20th Oct, 2024",
    button: "Register Now",

    btnLink: "https://us06web.zoom.us/meeting/register/tZYsf-mqrjwjH92GOs0E4DnNAWaDV-rCb9tU",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: pm,
    workshop: "5 Day Performance Marketing",
    mentor: "Deepika Manoharan",
    date: "14th-18th Oct, 2024",
    button: "Register Now",
    btnLink: "https://us06web.zoom.us/meeting/register/tZAlceyopjIsG9HA2lriQO0iydm4AmEBCAtr",
    status: "Upcoming",

    gif: "green"
  },
  {
    bannerImg: mern,
    workshop: "MERN Stack Workshop",
    mentor: "Satya Deep G",
    date: "14th-18th Oct, 2024",
    button: "Register Now",

    btnLink: "",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: cysec,
    workshop: "Cyber Security Workshop",
    mentor: "",
    date: "26th-27th Oct, 2024",
    button: "Register Now",

    btnLink: "",
    status: "Upcoming",
    gif: "green"
  }


];

const upComingContent = [

  // {
  //   bannerImg: dsa,
  //   workshop: "5 Day - DSA Course",
  //   mentor: "Bharat Sreeram",
  //   date: "23st-27nd Sep, 2024",
  //   button: "Register Now",
  //   btnLink:
  //     "https://us06web.zoom.us/meeting/register/tZUscumhrjosHtQR4IBGtCVOZxbWv9yccxM7",
  //   status: 'Upcoming',
  //   gif: "green",

  // },



  // {
  //   bannerImg: ad,
  //   workshop: "2 Day Android Development",
  //   mentor: "Pankaj Takale",
  //   date: "21st-22nd Sep, 2024",
  //   button: "Register Now",
  //   btnLink: "https://us06web.zoom.us/meeting/register/tZUodeGupjwvHN2HrhtrkssxwfMlTs7JNZyz",
  //   status: "Upcoming",
  //   gif: "green"
  // },
  {
    bannerImg: flutter,
    workshop: "10 Day Flutter Bootcamp",
    mentor: "Narayan Vyas",
    date: "13th-22nd Oct, 2024",
    button: "Register Now",

    btnLink: "https://upskill.coursevita.com/flutter-app-development",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: frontend,
    workshop: "Full Stack 5 Day Project",
    mentor: "Satya Deep G",
    date: "7th-11th Oct, 2024",
    button: "Register Now",

    btnLink: "https://us06web.zoom.us/meeting/register/tZIofuGppjwoHNRNIkY2H_kj5RhkO5BLReGL",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: accacma,
    workshop: "ACCA/CMA Workshop",
    mentor: "Deeksha",
    date: "19th-20th Oct, 2024",
    button: "Register Now",

    btnLink: "https://us06web.zoom.us/meeting/register/tZYsf-mqrjwjH92GOs0E4DnNAWaDV-rCb9tU",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: pm,
    workshop: "5 Day Performance Marketing",
    mentor: "Deepika Manoharan",
    date: "14th-18th Oct, 2024",
    button: "Register Now",
    btnLink: "https://us06web.zoom.us/meeting/register/tZAlceyopjIsG9HA2lriQO0iydm4AmEBCAtr",
    status: "Upcoming",

    gif: "green"
  },
  {
    bannerImg: mern,
    workshop: "MERN Stack Workshop",
    mentor: "Satya Deep G",
    date: "14th-18th Oct, 2024",
    button: "Register Now",

    btnLink: "",
    status: "Upcoming",
    gif: "green"
  },
  {
    bannerImg: cysec,
    workshop: "Cyber Security Workshop",
    mentor: "",
    date: "26th-27th Oct, 2024",
    button: "Register Now",

    btnLink: "",
    status: "Upcoming",
    gif: "green"
  }
];

import { motion } from 'framer-motion';
import { styled } from '@mui/system';



const StyledSlider = styled(Slider)({
  '& .slick-slide': {
    transition: 'transform 0.3s ease-in-out',
  },
  '& .slick-prev, & .slick-next': {
    zIndex: 10, // Ensure arrows are visible
  },
});
//   function SampleNextArrow({ onClick, currentSlide, slideCount }) {
//     const isDisabled = currentSlide >= slideCount - 1;
//     return (
//       <button
//         style={{
//           background: 'none',
//           border: 'none',
//           cursor: isDisabled ? 'default' : 'pointer',
//           position: 'absolute',
//           right: '-35px',
//           top: '50%',
//           transform: 'translateY(-50%)',
//           opacity: isDisabled ? 0.5 : 1,
//           pointerEvents: isDisabled ? 'none' : 'auto',
//         }}
//         onClick={onClick}
//         disabled={isDisabled}
//       >
//         <img
//           src={parrow} // Show sarrow when disabled
//           alt={isDisabled ? "Disabled Next Arrow" : "Next Arrow"}
//           style={{ width: '30px', height: '30px' }}
//         />
//       </button>
//     );
// }

// SamplePrevArrow Component
// function SamplePrevArrow({ onClick, currentSlide, slideCount }) {
//   // Disable button if at the last slide
//   const isDisabled = currentSlide === 0;

//   return (
//       <button
//           style={{
//               background: 'none',
//               border: 'none',
//               cursor: isDisabled ? 'default' : 'pointer',
//               position: 'absolute',
//               left: '-35px',
//               top: '50%',
//               transform: 'translateY(-50%)',
//               opacity: isDisabled ? 0.5 : 1,
//               pointerEvents: isDisabled ? 'none' : 'auto',
//           }}
//           onClick={onClick}
//           disabled={isDisabled}
//       >
//           <img
//               src={parrow} // Show parrow (previous arrow)
//               alt={isDisabled ? "Disabled Previous Arrow" : "Prev Arrow"}
//               style={{ 
//                   width: '30px', 
//                   height: '30px', 
//                   transform: 'rotate(180deg)' // Rotate the previous arrow
//               }}
//           />
//       </button>
//   );
// }
function SampleNextArrow({ onClick, currentSlide, slideCount }) {
  // Disable button if at the last slide
  const isDisabled = currentSlide >= slideCount - 4;

  // Determine which arrow to show
  const arrowSrc = isDisabled ? RParrow : RSarrow; // Show RParrow if at the last slide, else RSarrow

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        cursor: isDisabled ? 'default' : 'pointer',
        position: 'absolute',
        right: '-35px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: isDisabled ? 1 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <img
        src={arrowSrc} // Use the determined arrow source
        alt={isDisabled ? "Disabled Next Arrow" : "Next Arrow"}
        style={{ width: '30px', height: '30px' }}
      />
    </button>
  );
}
function SamplePrevArrow({ onClick, currentSlide, slideCount }) {
  // Disable button if at the first slide
  const isDisabled = currentSlide === 0;

  // Determine which arrow to show
  const arrowSrc = currentSlide >= 1 ? LSarrow : LParrow; // Show LSarrow if past 3rd slide, else LParrow

  return (
    <button
      style={{
        background: 'none',
        border: 'none',
        cursor: isDisabled ? 'default' : 'pointer',
        position: 'absolute',
        left: '-35px',
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: isDisabled ? 1 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <img
        src={arrowSrc} // Use the determined arrow source
        alt={isDisabled ? "Disabled Previous Arrow" : "Prev Arrow"}
        style={{
          width: '30px',
          height: '30px',
          //transform: 'rotate(180deg)' // Uncomment if you want to rotate the previous arrow
        }}
      />
    </button>
  );
}




const Workshops = () => {
  const [currentWorkshops, setCurrentWorkshops] = useState(true);
  const [content, setContent] = useState(allContent); // Replace with your content
  const [width, setWidth] = useState('1600px');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const sliderRef = useRef(null); // Slider reference

  const animations = {
    initial: { opacity: 0, scale: 1 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1 },
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
    },
  };

  // Settings for all workshops slider
  const allWorkshopsSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SamplePrevArrow currentSlide={currentSlide} />,
    nextArrow: <SampleNextArrow currentSlide={currentSlide} slideCount={totalSlides} />,
    cssEase: 'linear',
    afterChange: (index) => setCurrentSlide(index),
    beforeChange: () => setTotalSlides(content.length),
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 1 } },

      { breakpoint: 800, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  // Settings for upcoming workshops slider without arrows
  const upcomingWorkshopsSettings = {
    ...allWorkshopsSettings, // Reuse the same settings as allWorkshopsSettings
  };

  return (
    <Box className="workshops">
      <Box className="headerText" sx={{ px: '16px', pb: { xs: '30px', sm: '40px' } }}>
        <Typography className="text1" sx={{ pb: '5px', fontSize: { xs: '20px', sm: '32px' } }}>
          Workshops
        </Typography>
        <Typography className="text2" sx={{ fontSize: { xs: '20px', sm: '32px' } }}>
          Learn from the best with our workshops
        </Typography>
      </Box>

      <Box className="btnWrapper" sx={{ gap: { xs: '16px', sm: '24px' }, px: '16px' }}>
        <Button
          className="workshopBtn"
          sx={{ width: { xs: '50%', sm: '326px' }, height: { xs: '41px', sm: '68px' }, fontSize: { xs: '14px', sm: '20px' }, p: { sm: '20px' } }}
          style={{ color: currentWorkshops ? 'white' : '#0F002E', backgroundColor: currentWorkshops ? '#0F002E' : 'white' }}
          onClick={() => {
            setCurrentWorkshops(true);
            setContent(allContent); // Adjust based on your content
            setWidth('1600px');
          }}
        >
          All Workshops
        </Button>
        <Button
          sx={{ width: { xs: '50%', sm: '326px' }, height: { xs: '41px', sm: '68px' }, fontSize: { xs: '14px', sm: '20px' } }}
          className="workshopBtn"
          style={{ color: !currentWorkshops ? 'white' : '#0F002E', backgroundColor: !currentWorkshops ? '#0F002E' : 'white' }}
          onClick={() => {
            setCurrentWorkshops(false);
            setContent(upComingContent); // Adjust based on your content
            setWidth('1600px');
          }}
        >
          Upcoming Workshops
        </Button>
      </Box>

      <Box>
        <Box sx={{
          width: '90%',
          p: { md: '40px 32px', xs: '25px 16px' },
          margin: 'auto',
          maxWidth: width,
          position: 'relative',
          boxShadow: 'none',
          '&:hover': { boxShadow: 'none' }
        }}>
          <Slider ref={sliderRef} {...(currentWorkshops ? allWorkshopsSettings : upcomingWorkshopsSettings)}>
            {content.map((item, index) => (
              <motion.div key={index} initial="initial" animate="animate" exit="exit" transition="transition" variants={animations} style={{ display: 'inline-block' }}>
                <Box sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0 10px',
                  boxShadow: 'none',
                  '&:hover': { boxShadow: 'none' }
                }}>
                  <WorkshopCard workshop={item} /> {/* Adjust this component based on your data */}
                </Box>
              </motion.div>
            ))}
          </Slider>
        </Box>

      </Box>
    </Box>
  );
};

export default Workshops;














// Styled components for MUI elements




// Custom Next Arrow
// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   const [arrowColor, setArrowColor] = useState('#0F002E'); // Initial color

//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: 'block',
//         position: 'absolute',
//         right: '-35px',
//         top: '50%',
//         transform: 'translateY(-50%)',
//       }}
//       onMouseEnter={() => setArrowColor('blue')}  // Change to hover color
//       onMouseLeave={() => setArrowColor('#0F002E')}  // Revert to default color
//       onClick={() => {
//         setArrowColor('#b593ff');  // Change color on click
//         onClick();
//       }}
//     >
//       {/* Image added here */}
//       <img
//         src={parrow}  // Next arrow image
//         alt="Next Arrow"
//         style={{
//           width: '30px',
//           height: '30px',
//           filter: `drop-shadow(0 0 5px ${arrowColor})`,  // Optional color effect on hover/click
//         }}
//       />
//     </div>
//   );
// }

// function SampleNextArrow(props) {
//   const { className, style } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: 'block',
//         color: arrowColor,
//         fontSize: '30px',
//         position: 'absolute',
//         right: '-35px',
//         top: '50%',
//         transform: 'translateY(-50%)',
//       }}
//       onMouseEnter={() => setArrowColor('blue')} // Change to hover color
//       onMouseLeave={() => setArrowColor('#0F002E')} // Revert to default color
//       onClick={() => {
//         setArrowColor('#b593ff'); // Change color on click
//         sliderRef.current.slickNext();
//       }}
//     />
//   );
// }

// Custom Prev Arrow
// function SamplePrevArrow(props) {
//   const { className, style } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: 'block',
//         color: arrowColor,
//         fontSize: '30px',
//         position: 'absolute',
//         left: '-35px',
//         top: '50%',
//         transform: 'translateY(-50%)',
//       }}
//       onMouseEnter={() => setArrowColor('blue')} // Change to hover color
//       onMouseLeave={() => setArrowColor('#0F002E')} // Revert to default color
//       onClick={() => {
//         setArrowColor('#b593ff'); // Change color on click
//         sliderRef.current.slickPrev();
//       }}
//     />
//   );
// }
// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   const [arrowColor, setArrowColor] = useState('#0F002E'); // Initial color

//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//         display: 'block',
//         position: 'absolute',
//         left: '-35px',
//         top: '50%',
//         transform: 'translateY(-50%)',
//       }}
//       onMouseEnter={() => setArrowColor('blue')}  // Change to hover color
//       onMouseLeave={() => setArrowColor('#0F002E')}  // Revert to default color
//       onClick={() => {
//         setArrowColor('#b593ff');  // Change color on click
//         onClick();
//       }}
//     >
//       {/* Image added here */}
//       <img
//         src={sarrow}  // Previous arrow image
//         alt="Prev Arrow"
//         style={{
//           width: '30px',
//           height: '30px',
//           filter: `drop-shadow(0 0 5px ${arrowColor})`,  // Optional color effect on hover/click
//         }}
//       />
//     </div>
//   );
// }