import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, InputAdornment, Button } from "@mui/material";
import header_img from "../../../assets/images/ProjectsPage/header_img.svg";
import SearchIcon from "@mui/icons-material/Search";
import "./header.scss";
import { useParams } from "react-router-dom";
import logo1 from "../../../assets/images/ProjectsPage/logo1.png";
import logo2 from "../../../assets/images/ProjectsPage/logo2.svg";
import logo3 from "../../../assets/images/ProjectsPage/logo3.svg";
import logo4 from "../../../assets/images/ProjectsPage/logo4.svg";
import logo5 from "../../../assets/images/ProjectsPage/logo5.svg";
import logo6 from "../../../assets/images/ProjectsPage/logo6.svg";
import Correct from "../../../assets/images/mockInterview/correct.svg";
import leftImg from "../../../assets/images/ProjectsPage/left_img.png"
import rightImg from "../../../assets/images/ProjectsPage/right_img.png"
import middleImg from "../../../assets/images/ProjectsPage/middle_img.png"


import project_img from "../../../assets/images/ProjectsPage/ProjectHero.png"
function Header({ targetFeaturedProjects }) {
  const { project_type } = useParams();

  const mainRouteData = {
    title: "Build Projects with us",
    description:
      "Transform Your Skills into Real-World Solutions with Our Guided Projects and Collaborative Community",
  };

  const dynamicRouteData = {
    data_analytics: {
      title: "Data Analytics Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    data_science: {
      title: "Data Science Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    data_visualization: {
      title: "Data Visualization Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    data_engineering: {
      title: "Data Engineering Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    devops: {
      title: "Devops Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
    ux_ui_design: {
      title: "UX/UI Projects",
      description:
        "Build a Data Analytics project to showcase your skills in data collection, analysis, and visualisation. This project demonstrates your ability to transform raw data into actionable insights using various analytical tools and techniques,",
    },
  };

  const dataToDisplay = project_type
    ? dynamicRouteData[project_type]
    : mainRouteData;

  const data = [
    {
      id: 1,
      logo: logo2,
      title: "Location Analytics for Delivery Optimisation",
      description:
        "Utilize GIS and location data to analyze the geographical spread of orders and delivery efficiency. Implement models to predict high-demand areas and optimize delivery logistics accordingly. Use Python to analyze traffic data and weather conditions impacting delivery times and customer satisfaction.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 2,
      logo: logo1,
      title: "Government of Telangana - Agriculture Price Estimation",
      description:
        "Develop a predictive model using historical market data and real-time weather information to forecast crop prices. Implement machine learning techniques to refine accuracy based on trends and external factors. Provide farmers with accessible dashboards for current market conditions and predictions.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 3,
      logo: logo3,
      title: "Naukri - Job Market Analysis",
      description:
        "Examine the job postings and applicant data to identify trends in industries, roles, and geographies. Use data visualisation to create interactive dashboards that allow users to explore job market dynamics. Implement machine learning algorithms to recommend jobs to users based on their profiles and previous interactions.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 4,
      logo: logo4,
      title: "Zerodha - Financial Market Analysis Tool",
      description:
        "Aggregate financial data from multiple sources using advanced SQL techniques. Analyze historical market trends and correlations between different financial instruments using Python. Develop a set of visual analytic tools in Python to help users visualize market trends and potential investment opportunities.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 5,
      logo: logo5,
      title: "Myntra - Marketing and Sales Optimization",
      description:
        "Analyze customer interaction data and purchase history to optimize marketing campaigns and product placements. Utilize machine learning to predict customer buying behavior and personalize marketing efforts. Develop dashboards in Tableau to track marketing effectiveness and sales trends in real-time.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
    {
      id: 6,
      logo: logo6,
      title: "Apollo - Health Insurance Optimization",
      description:
        "Analyze historical claim data to identify patterns in claims and optimize insurance operations. Use predictive analytics to forecast claim amounts and frequencies, assisting in premium setting and risk assessment. Develop interactive dashboards for insurers to monitor claims processes, customer feedback, and operational efficiency.",
      prerequisites: "Power BI, Tableau",
      hours: 100,
    },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter projects based on the search query
  const filteredProjects = data.filter((project) => {
    const query = searchQuery.toLowerCase();
    return (
      project.title.toLowerCase().includes(query) ||
      project.description.toLowerCase().includes(query) ||
      project.prerequisites.some((prerequisite) =>
        prerequisite.toLowerCase().includes(query)
      )
    );
  });

  return (
    <>
      <Box
  sx={{
    background: "white",
    borderRadius: "10px",
    padding: { xs: "10px", md: "50px" },
    margin: { xs: "20px", md: "35px" },
    display: "flex",
    alignItems: { xs: "center", lg: "flex-start" },
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
  }}
>
  {/* Text Box */}
  <Box
    sx={{
      display: "flex",
      alignItems: { xs: "center", lg: "flex-start" },
      flexDirection: "column",
      textAlign: { xs: "center", lg: "left" },
      marginTop: { xs: '0', lg: '60px' },
    }}
  >
    {/* Heading */}
    <Box
      sx={{
        color: "black",
        fontFamily: "Outfit",
        fontSize: { xs: "32px", md: "40px" },
        fontWeight: "500",
        textAlign: { xs: "center", lg: "left" },
      }}
    >
      Build Projects with us
    </Box>

    {/* Description */}
    <Box
      sx={{
        color: 'var(--Filled, #000)',
        fontFamily: 'Outfit',
        fontSize: { xs: '18px', md: '20px' },
        fontWeight: 400,
        width: { xs: "100%", md: "605px" },
        textAlign: { xs: "center", lg: "left" },
      }}
    >
      Transform Your Skills into Real-World Solutions with Our Guided Projects and Collaborative Community
    </Box>

    {/* Numbered Boxes (1, 2, 3) */}
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "center", lg: "flex-start" },
        gap: '10px',
        width: { xs: "44%", sm: "24%", md: "30%" },
        marginTop: "20px",
        mt: { xs: 4, md: 4, lg: 8 },
        marginBottom: "30px",
      }}
    >
      {/* Box 1 */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            flexDirection: "row",
            textAlign: "left",
          }}
        >
          <Box
            component="img"
            src={Correct}
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              lineHeight: "1.2",
              whiteSpace: "nowrap",
              ml: -0.3,
              mt: 0.1,
            }}
          >
            Real-time project Experience
          </Typography>
        </Box>
      </Box>

      {/* Box 2 */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            flexDirection: "row",
            textAlign: "left",
            ml: { lg: 1.3 }
          }}
        >
          <Box
            component="img"
            src={Correct}
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              lineHeight: "1.2",
              whiteSpace: "nowrap",
              ml: -0.3,
              mt: 0.1,
            }}
          >
            Expert Guidance
          </Typography>
        </Box>
      </Box>

      {/* Box 3 */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            flexDirection: "row",
            textAlign: "left",
            ml: { lg: 1.3 }
          }}
        >
          <Box
            component="img"
            src={Correct}
            sx={{
              width: "18px",
              height: "18px",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              lineHeight: "1.2",
              whiteSpace: "nowrap",
              ml: -0.3,
              mt: 0.1,
            }}
          >
            Collaborative Learning
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: { xs: 'center', lg: 'flex-start' },
        alignItems: 'center',
        gap: '20px',
        width: '100%',
      }}
    >
      {/* Button Section */}
      <Button
        sx={{
          height: '52px',
          padding: '15px 40px',
          backgroundColor: '#7234F7',
          color: 'white',
          fontFamily: 'Outfit',
          textTransform: 'none',
          borderRadius: '8px',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#7234F7',
          },
        }}
      >
        Start your project
      </Button>

      {/* Image and Text Section */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            width: '160px',
            height: '43px',
            mr:{xs:0,md:2},
            ml:{xs:"50px"}
          }}
        >
          <img
            src={leftImg}
            alt="Left Image"
            style={{
              width: '43px',
              height: '43px',
              position: 'absolute',
              left: 0,
              zIndex: 1,
              borderRadius: '50%',
              border: '2px solid white',
            }}
          />
          <img
            src={middleImg}
            alt="Middle Image"
            style={{
              width: '43px',
              height: '43px',
              position: 'absolute',
              left: '30px',
              zIndex: 2,
              borderRadius: '50%',
              border: '2px solid white',
            }}
          />
          <img
            src={rightImg}
            alt="Right Image"
            style={{
              width: '43px',
              height: '43px',
              position: 'absolute',
              left: '60px',
              zIndex: 3,
              borderRadius: '50%',
              border: '2px solid white',
            }}
          />
        </Box>
        <Typography
          sx={{
            color: '#656565',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: 'normal',
            display: { xs: 'block', lg: 'inline-block' },
            whiteSpace: { lg: 'pre-wrap' },
            textAlign: { xs: 'left', lg: 'left' },
            ml: { lg: -7 }, // Adjusts the margin to shift text to the left
          }}
        >
          More than 2,000<br />people successfully completed their projects
        </Typography>
      </Box>
    </Box>
  </Box>

  {/* Image and Statistics Box */}
  <Box
  sx={{
    display: 'flex',
    justifyContent: { xs: 'center', lg: 'flex-end' },
    alignItems: 'center',
    position: 'relative',
    marginTop: { xs: '20px', lg: '40px' },
    marginBottom: { xs: '20px', lg: '40px' },


    width: { xs: '100%', lg: '50%' },
  }}
>
  <img
    src={project_img}
    alt="Project Image"
    style={{
      width: '70%',
      height: 'auto',
    }}
  />

  {/* Statistics Overlay */}
  <Box
    sx={{
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    }}
  >
    {/* 80% Learners Report Increased Confidence */}
    <Box
      sx={{
          backgroundColor: "#F7F7F7",
          padding: "10px 15px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "left",
          position: 'absolute',  // Setting it as absolute inside the flex box
          transform: "translateX(50%)",
          zIndex: 1,
        
          // Responsive control with media queries
          '@media (max-width:600px)': { // For small screens
            top: '-10px',  // Adjust top position for small screens
            left: '-80px',  // Adjust left position for small screens
            width: '190px',  // Adjust width for small screens
            // backgroundColor: "yellow"

          },
          '@media (min-width:601px) and (max-width:960px)': { // For medium screens (tablet size)
            top: '-120px',  // Adjust top position for tablets
            left: '-348px',  // Adjust left position for tablets
            width: '195px',  // Adjust width for tablets
            // backgroundColor: "pink"

          },
          '@media (min-width:961px) and (max-width:1280px)': { // For large screens
            top: '-160px',  // As provided
            left: '-480px',  // Adjust left position for large screens
            width: '195px',  // Adjust width for large screens
            // backgroundColor: "blue"
          },
          '@media (min-width:1281px)': { // For extra large screens
            top: '-100px',  // Adjust top position as desired
            left: '-708px',  // Adjust left position for extra large screens
            width: '195px',  // Keep the width as provided
          }
        }}
        
      // Fine-tune vertical alignment
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#7234F7",
          fontFamily: "Outfit",
        }}
      >
        80%
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "normal",
          color: "#000000",
          fontFamily: "Poppins"
        }}
      >
        Learners Report<br />Increased Confidence
      </Typography>
    </Box>

    {/* 75% Projects Published in Portfolios */}
    <Box
      sx={{
        backgroundColor: "#F7F7F7",
        padding: "10px 15px",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "left",
        position: 'absolute',
        transform: "translateY(-50%)",  // Adjust for precise placement
        zIndex: 2,
      
        // Responsive styling with @media queries
        '@media (max-width:600px)': { // For small screens
          top: '-40px',
          left: '-210px',
          width: '180px',
          // backgroundColor: "yellow",

        },
        '@media (min-width:601px) and (max-width:960px)': { // For medium screens (tablet size)
          top: '70px',
          left: '30px',
          width: '190px',
          // backgroundColor: "pink"

        },
        '@media (min-width:961px) and (max-width:1280px)': { // For large screens
          top: '210px',
          left: '10px',
          width: '190px',
          // backgroundColor: "blue"
        },
        '@media (min-width:1281px)': { // For extra large screens
          top: '156px',
          left: '-280px',
          width: '190px',
        }
      }}
      
    >
      <Typography
        sx={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#7234F7",
          fontFamily: "Outfit",
        }}
      >
        75%
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "normal",
          color: "#000000",
          fontFamily: "Poppins"
        }}
      >
        Projects Published<br />in Portfolios
      </Typography>
    </Box>
  </Box>
</Box>

</Box>


    </>
  );
}
export default Header;


