import React from "react";
import { Box, Button, Typography, Grid, Paper } from "@mui/material";
import "../helper/Helper.scss";
import interview from "../../../assets/images/helper/interview.jpeg"
import laptop from "../../../assets/images/helper/laptopimg.jpeg"
import Blinkit from "../../../assets/images/helper/Blinkit_logo.svg"
import Cred from "../../../assets/images/helper/cred_logo.png"
import myntra from "../../../assets/images/helper/myntra_logo.svg"
import netflix from "../../../assets/images/helper/netflix.svg"
import slack from "../../../assets/images/helper/slack_logo.svg"
import swiggy from "../../../assets/images/helper/swiggy_logo.svg"
import uber from "../../../assets/images/helper/Uber_logo.svg"
import zomato from "../../../assets/images/helper/Zomato_logo.svg"
import { useNavigate } from "react-router-dom";
const Helper = () => {
    const navigate=useNavigate();
    return (
        <>
            <Box
                className="testimonialsWrapper"
                sx={{
                    flexGrow: 1,
                    margin: "auto",
                    textAlign: { xs: "center", md: "center" }, // Center text on mobile, left on desktop
                    mt: { md: "50px", xs: "0px" },
                    p: { md: "30px 0", xs: "20px 0 10px 0" },
                }}
            >
                <Box className="testimonialsHeader" sx={{ pb: { md: "40px", xs: "20px" } }}>
                    <Typography className="headText" sx={{ fontSize: { xs: "20px", md: "32px" } }}>
                        Why we are
                    </Typography>
                    <Typography className="subText" sx={{ fontSize: { xs: "20px", md: "36px" } }}>
                        Best part of <span style={{ color: "#FE7465" }}>your learning journey</span>
                    </Typography>
                </Box>

                {/* Grid Section */}
                <Grid container spacing={2} justifyContent="center" sx={{ width: "100%" }}>
                    {/* Left Card */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            elevation={3}
                            sx={{
                                display: { xs: 'flex', sm: 'flex' },
                                p: 4,
                                borderRadius: 6,
                                display: 'flex',
                                background: 'linear-gradient(99deg, rgba(114, 52, 247, 0.10) 1.54%, rgba(66, 0, 150, 0.10) 98.12%)',
                                height: '100%',
                                flexDirection: { xs: 'column', md: 'row' },
                                marginLeft: { xs: 1.8, sm: 2 },
                            }}
                        >
                            {/* Left Section - Text and Button */}
                            <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'left' }, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 2,
                                        fontSize: { xs: '18px', md: '24px' },
                                        color: '#000',
                                        fontFamily: 'Outfit',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Project Experience
                                </Typography>
                                <Typography
                                    sx={{
                                        mb: 2,
                                        fontSize: { xs: '14px', md: '16px' },
                                        color: '#000',
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: 300,
                                        lineHeight: '150%',
                                    }}
                                >
                                    We empower each student with hands-on project experience to build practical skills, knowledge, and confidence to thrive in a dynamic world.
                                </Typography>
                                <Button
                                    sx={{
                                        borderRadius: '6px',
                                        background: '#FFF',
                                        width: { xs: '100%', sm: '100%', md: '200px' },
                                        height: '50px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexShrink: 0,
                                        '&:hover': { backgroundColor: '#FFF' },
                                        color: '#0F002E',
                                        fontFamily: 'Outfit',
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        textTransform: 'none',
                                        mt: { xs: 0, sm: 0, md: 0 }

                                    }}
                                    onClick={()=>{navigate('/projects')}}
                                >
                                    Explore Projects
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column',sm:"column", md: 'row' },
                                    mt :{xs:3.3,sm:3.3,md:0}
                                }}
                            >
                                <Box

                                    className="logos"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                        //     gap: 10,
                                        //     overflow: 'hidden',
                                        height: { xs: 'auto', md: '210px' },
                                        //     mt: { xs: 2, md: 0 },
                                        //     ml: { xs: 0, md: 0 },
                                    }}
                                >
                                    {/* First Column - Moving Up (md and above) or Left (xs) */}
                                    <Box
                                        className="logos-slide"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                    //     animation: {
                                    //         xs: 'scrollHorizontal 5s linear infinite',
                                    //         md: 'scrollVerticalUp 5s linear infinite',
                                    //     },
                                    }}
                                    >
                                        {[
                                            { img: myntra, bgColor: 'white', size: '45%' },
                                            { img: swiggy, bgColor: '#FC8019', size: '55%' },
                                            { img: uber, bgColor: '#000000', size: '55%' },
                                            { img: myntra, bgColor: 'white', size: '45%' },

                                            { img: Blinkit, bgColor: '#FFE24B', size: '58%' },
                                        ].map((brand, idx) => (
                                            <Box
                                                key={idx}
                                                sx={{
                                                    width: 65,
                                                    height: 44,
                                                    backgroundColor: brand.bgColor,
                                                    borderRadius: 2,
                                                    mb: { md: 1, xs: 0 },
                                                    mr: { xs: 1, md: 0 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={brand.img}
                                                    alt="Brand Logo"
                                                    style={{
                                                        maxWidth: brand.size,
                                                        maxHeight: brand.size,
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box
                                        className="logos-slide"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                    //     animation: {
                                    //         xs: 'scrollHorizontal 5s linear infinite',
                                    //         md: 'scrollVerticalUp 5s linear infinite',
                                    //     },
                                    }}
                                    >
                                        {[
                                            { img: myntra, bgColor: 'white', size: '45%' },
                                            { img: swiggy, bgColor: '#FC8019', size: '55%' },
                                            { img: uber, bgColor: '#000000', size: '55%' },
                                            { img: Blinkit, bgColor: '#FFE24B', size: '58%' },
                                            { img: uber, bgColor: '#000000', size: '55%' },
                                        ].map((brand, idx) => (
                                            <Box
                                                key={idx}
                                                sx={{
                                                    width: 65,
                                                    height: 44,
                                                    backgroundColor: brand.bgColor,
                                                    borderRadius: 2,
                                                    mb: { md: 1, xs: 0 },
                                                    mr: { xs: 1, md: 0 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={brand.img}
                                                    alt="Brand Logo"
                                                    style={{
                                                        maxWidth: brand.size,
                                                        maxHeight: brand.size,
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>

                                    {/* Second Column - Moving Down (md and above) or Right (xs) */}
                                    {/* <Box
                                    className="logo-slide"
                                    // sx={{
                                    //     display: 'flex',
                                    //     flexDirection: { xs: 'row', md: 'column' },
                                    //     animation: {
                                    //         xs: 'scrollHorizontal 5s linear infinite reverse',
                                    //         md: 'scrollVerticalDown 5s linear infinite',
                                    //     },
                                    //     mt: { xs: 8, sm: 8, md: -40 },
                                    //     ml: { xs: -40, sm: -10, md: 10 },
                                    // }}
                                >
                                    {[
                                        { img: slack, bgColor: 'white', size: '55%' },
                                        { img: Cred, bgColor: 'white', size: '70%' },
                                        { img: zomato, bgColor: '#E23744', size: '60%' },
                                        { img: netflix, bgColor: '#000000', size: '55%' },
                                    ].map((brand, idx) => (
                                        <Box
                                            key={idx}
                                            sx={{
                                                width: 65,
                                                height: 44,
                                                backgroundColor: brand.bgColor,
                                                borderRadius: 2,
                                                mb: { md: 1, xs: 0 },
                                                mr: { xs: 1, md: 0 },
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                src={brand.img}
                                                alt="Brand Logo"
                                                style={{
                                                    maxWidth: brand.size,
                                                    maxHeight: brand.size,
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box> */}
                                </Box>
                                <Box

                                    className="logos-1"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                        //     gap: 10,
                                        //     overflow: 'hidden',
                                        height: { xs: 'auto', md: '210px' },
                                        //     mt: { xs: 2, md: 0 },
                                        //     ml: { xs: 0, md: 0 },
                                    }}
                                >
                                    {/* First Column - Moving Up (md and above) or Left (xs) */}
                                    <Box
                                        className="logos-slide-1"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                    //     animation: {
                                    //         xs: 'scrollHorizontal 5s linear infinite',
                                    //         md: 'scrollVerticalUp 5s linear infinite',
                                    //     },
                                    }}
                                    >
                                        {[
                                             { img: slack, bgColor: 'white', size: '55%' },
                                             { img: Cred, bgColor: 'white', size: '70%' },
                                             { img: zomato, bgColor: '#E23744', size: '60%' },
                                             { img: Cred, bgColor: 'white', size: '70%' },

                                             { img: netflix, bgColor: '#000000', size: '55%' },
                                        ].map((brand, idx) => (
                                            <Box
                                                key={idx}
                                                sx={{
                                                    width: 65,
                                                    height: 44,
                                                    backgroundColor: brand.bgColor,
                                                    borderRadius: 2,
                                                    mb: { md: 1, xs: 0 },
                                                    mr: { xs: 1, md: 0 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={brand.img}
                                                    alt="Brand Logo"
                                                    style={{
                                                        maxWidth: brand.size,
                                                        maxHeight: brand.size,
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box
                                        className="logos-slide-1"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'row', md: 'column' },
                                    //     animation: {
                                    //         xs: 'scrollHorizontal 5s linear infinite',
                                    //         md: 'scrollVerticalUp 5s linear infinite',
                                    //     },
                                    }}
                                    >
                                        {[
                                             { img: slack, bgColor: 'white', size: '55%' },
                                             { img: Cred, bgColor: 'white', size: '70%' },
                                             { img: zomato, bgColor: '#E23744', size: '60%' },

                                             { img: Cred, bgColor: 'white', size: '70%' },
                                             { img: netflix, bgColor: '#000000', size: '55%' },
                                        ].map((brand, idx) => (
                                            <Box
                                                key={idx}
                                                sx={{
                                                    width: 65,
                                                    height: 44,
                                                    backgroundColor: brand.bgColor,
                                                    borderRadius: 2,
                                                    mb: { md: 1, xs: 0 },
                                                    mr: { xs: 1, md: 0 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <img
                                                    src={brand.img}
                                                    alt="Brand Logo"
                                                    style={{
                                                        maxWidth: brand.size,
                                                        maxHeight: brand.size,
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>


                    {/* Right Card - Interview Preparation */}
                    <Grid item xs={12} md={4}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: { xs: 4, md: 3 }, // Reduce padding for medium screens
                                borderRadius: 6,
                                marginLeft: { xs: 1.8, sm: 2 },
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                background: "linear-gradient(110deg, #5147C7 0.74%, #DECEFF 111.19%)",
                                textAlign: { xs: "center", md: "left" },
                                boxSizing: 'border-box' // Ensures padding doesn't overflow
                            }}
                        >
                            {/* Image with overlay text */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: { xs: 'center', sm: "center", md: "flex-start" }, // Fix alignment for md
                                    justifyContent: { xs: 'center', sm: "center", md: "flex-start" },
                                    mb: 2,
                                    textAlign: { xs: "center", sm: "left", md: "left" },
                                    mt: { xs: 0, sm: 0, md: 1 },
                                    flexWrap: "wrap" // Wrap text and image on smaller spaces
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: "Outfit",
                                        fontSize: { xs: '18px', md: '22px' }, // Slightly smaller font size for md
                                        color: 'white',
                                        mr: 1,
                                        wordWrap: 'break-word', // Prevent text overflow
                                        maxWidth: '100%' // Prevent the text from overflowing
                                    }}
                                >
                                    Interview
                                </Typography>

                                {/* Insert Image */}
                                <Box
                                    component="img"
                                    src={interview} // Ensure this is a valid image URL or path
                                    alt="Interview and Preparation"
                                    sx={{
                                        borderRadius: '55px',
                                        width: '80px', // Reduce image size slightly
                                        height: '35px',
                                        mx: 1,
                                        background: "url(<path-to-image>) lightgray 0px -7.6px / 100% 176% no-repeat",
                                    }}
                                />

                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontFamily: "Outfit",
                                        fontSize: { xs: '18px', md: '22px' }, // Slightly smaller font size for md
                                        color: 'white',
                                        mr: 1,
                                        wordWrap: 'break-word', // Prevent text overflow
                                        maxWidth: '100%' // Prevent the text from overflowing
                                    }}
                                >
                                    Preparation
                                </Typography>
                            </Box>

                            {/* Description Text */}
                            {/* <Typography
                                sx={{
                                    mb: {xs:0,sm:0,md:0},
                                    fontSize: { xs: "14px", md: "15px" }, // Reduce text size for md
                                    color: "#FFF",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "150%",
                                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                    wordWrap: 'break-word', // Prevent text overflow
                                    mt: { xs: 0, sm: 0, md: 0 }

                                }}
                            >
                                Prepare for interviews now easily without any fear. CourseVita is here to guide you every step of the<br/> way! 
                            </Typography> */}
                            <Typography
                                sx={{
                                    mb: { xs: 1, sm: 1, md: 0 },
                                    fontSize: { xs: "14px", md: "16px" }, // Adjust font size for md
                                    color: "#FFF",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: { xs: "150%", md: "140%" }, // Adjust line height for better wrapping
                                    textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
                                    wordWrap: 'break-word', // Prevent text overflow
                                    mt: { xs: 0, sm: 0, md: 0 },
                                    maxWidth: { sm: "100%", md: "280px", lg: "350px" }, // Set max-width to control line breaks at md
                                }}
                            >
                                Prepare for interviews now easily without any fear. CourseVita is here to guide you every step of the way!
                            </Typography>




                            {/* Button */}
                            <Button
                                sx={{
                                    borderRadius: '6px',
                                    background: '#FFF',
                                    width: { xs: "100%", sm: "100%", md: '200px' },
                                    height: '50px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexShrink: 0,
                                    '&:hover': { backgroundColor: '#FFF' },
                                    color: "#0F002E",
                                    fontFamily: "Outfit",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    textTransform: 'none',
                                    alignSelf: { xs: 'center', ms: "center", md: 'flex-start' },
                                    mx: { xs: 'auto', sm: 0 },
                                    overflow: 'hidden', // Ensure button doesn't overflow
                                    mt: {
                                        xs: 0,    // Extra small devices (0px and up)
                                        sm: 0,    // Small devices (600px and up)
                                        md: 3,  // Medium devices (900px and up)
                                    }
                    
                            
                                    
                                }}
                                    onClick={()=>{navigate('/careerCounselling')}}

                            >
                                Start mock interview
                            </Button>
                        </Paper>
                    </Grid>





                    {/* Bottom Section - Career Finder */}
                    <Grid item xs={12} md={10}>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 4,
                                mt: { xs: 0, sm: 0, md: 2 },
                                marginLeft: { xs:1.8, sm: 2 },
                                borderRadius: 6,
                                display: 'flex',
                                flexDirection: { xs: 'column-reverse', md: 'row' }, // Adjust layout based on screen size
                                alignItems: 'center',
                                height: '100%',
                                background: "var(--dr, linear-gradient(90deg, #D06567 0%, #DE93B9 106.93%))",
                                textAlign: { xs: "center", md: "left" }, // Center text on mobile, left on desktop
                            }}
                        >
                            {/* Image section */}
                            <Box
                                sx={{
                                    ml: { md: 0 },
                                    mt: { xs: 2, sm: 0 },
                                    flexShrink: 0,
                                    mr: { xs: 0, md: 1 }, // Add margin to the right of the image in desktop view
                                    mb: { xs: 4, md: 0 }, // Add margin below the image in mobile view
                                    width: { xs: '100%', md: '320px' }, // Full width on small screens, 40% on medium+
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={laptop} // Make sure to reference the correct path to your image
                                    alt="Career Finder Image"
                                    style={{ borderRadius: '10px', maxWidth: '100%' }}
                                />
                            </Box>

                            {/* Text and Button section */}
                            <Box sx={{ flexGrow: 1, ml: { md: 2 } }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'white',
                                        fontFamily: "Outfit",
                                        fontSize: { xs: '18px', md: '24px' },
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        mb: 2,
                                        textAlign: { xs: "center", md: "left" }, // Center text on mobile, left on desktop
                                    }}
                                >
                                    Find your career easily
                                </Typography>
                                <Typography
                                    sx={{
                                        mb: 2,
                                        fontSize: { xs: "14px", md: "16px" },
                                        color: "#FFF",
                                        fontFamily: "Poppins",
                                        fontStyle: "normal",
                                        fontWeight: 300,
                                        lineHeight: "150%",
                                        textAlign: { xs: "center", md: "left" }, // Center text on mobile, left on desktop
                                    }}>
                                    We help you find your career! Access top courses from 3000+ Indian colleges, from cooking to engineering. We guide you to achieve your dreams through our career counselling.
                                </Typography>
                                <Box sx={{ display: "flex",flexDirection: {xs:"column",sm:"row",md:"row"}, justifyContent: { xs: "center", md: "left" }, flexWrap: "wrap" }}>
                                    <Button
                                        sx={{
                                            borderRadius: '6px',
                                            background: '#FFF',
                                            mt: { xs: 1, sm: 1, md: 1 },
                                            width: { xs: "100%", sm: "100%", md: '180px' },
                                            height: '50px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexShrink: 0,
                                            '&:hover': { backgroundColor: '#FFF' },
                                            color: "#0F002E",
                                            fontFamily: "Outfit",
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            textTransform: 'none',
                                            alignSelf: 'center', // Center the button on mobile
                                            mr: { xs: 0, sm: 0, md: 2, lg: 2 },
                                        }}
                                         onClick={()=>{navigate('/findyourcareer')}}
                                    >
                                        Career Finder
                                    </Button>
                                    <Button
                                        sx={{
                                            borderRadius: '6px',
                                            width: { xs: "100%", sm: "100%", md: '180px' },
                                            mt: { xs: 1, sm: 1, md: 1 },
                                            mb: { xs: 1, sm: 1, md: 0 },
                                            height: '50px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexShrink: 0,
                                            '&:hover': { backgroundColor: 'none' },
                                            color: "white",
                                            fontFamily: "Outfit",
                                            fontSize: "16px",
                                            fontWeight: 500,
                                            textTransform: 'none',
                                            alignSelf: 'center', // Center the button on mobile
                                            borderRadius: '6px',
                                            border: "1px solid var(--White, #FFF)",
                                        }}
                                         onClick={()=>{navigate('/careerCounselling')}}
                                    >
                                        Career Counselling
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Helper;