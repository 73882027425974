export const personaAssesment = [
  {
    id: 1,
    question: "How do you spend your free time?",
    options: {
      A: "Coding projects",
      B: "Creating art",
      C: "Analyzing data",
      D: "Leading groups",
      E: "Business ideas",
    },
  },
  {
    id: 2,
    question: "How do you approach new tasks?",
    options: {
      A: "Technical research",
      B: "Creative solutions",
      C: "Step-by-step analysis",
      D: "Delegating tasks",
      E: "Risk assessment",
    },
  },
  {
    id: 3,
    question: "What work environment suits you?",
    options: {
      A: "Tech-focused",
      B: "Flexible and creative",
      C: "Structured and clear",
      D: "Collaborative and leading",
      E: "Dynamic and fast-paced",
    },
  },
  {
    id: 4,
    question: "How do you solve problems at work?",
    options: {
      A: "Technical skills",
      B: "Innovative ideas",
      C: "Detailed analysis",
      D: "Leading the team",
      E: "Strategic planning",
    },
  },
  {
    id: 5,
    question: "What motivates you?",
    options: {
      A: "Mastering tech skills",
      B: "Creating new ideas",
      C: "Solving complex problems",
      D: "Leading and achieving",
      E: "Building a business",
    },
  },
  {
    id: 6,
    question: "How do you prefer to learn?",
    options: {
      A: "Hands-on practice",
      B: "Creative exploration",
      C: "Detailed reading",
      D: "Leadership workshops",
      E: "Learning by doing",
    },
  },
  {
    id: 7,
    question: "What role do you take in a team?",
    options: {
      A: "Tech expert",
      B: "Idea generator",
      C: "Detail analyst",
      D: "Team leader",
      E: "Project initiator",
    },
  },
  {
    id: 8,
    question: "How do you stay updated?",
    options: {
      A: "Tech blogs",
      B: "Creative workshops",
      C: "Industry reports",
      D: "Networking events",
      E: "Market analysis",
    },
  },
  {
    id: 9,
    question: "Which statement fits you?",
    options: {
      A: "Tech field expert",
      B: "Innovate ideas",
      C: "Solve challenges",
      D: "Inspire teams",
      E: "Start businesses",
    },
  },
];

export const technicalExpert = [
  {
    id: 1,
    question: "How do you handle debugging complex code?",
    options: {
      A: "Confidently (Advanced)",
      B: "With some difficulty (Intermediate)",
      C: "Very challenging (Beginner)",
      D: "Rarely debug (Beginner)",
    },
  },
  {
    id: 2,
    question: "Which programming task do you find most difficult?",
    options: {
      A: "Learning new languages (Intermediate)",
      B: "Writing efficient code (Intermediate)",
      C: "System architecture (Advanced)",
      D: "Integrating new technologies (Intermediate)",
      E: "Basic programming concepts (Beginner)",
    },
  },
  {
    id: 3,
    question: "How frequently do you engage in coding projects?",
    options: {
      A: "Daily (Advanced)",
      B: "Weekly (Intermediate)",
      C: "Occasionally (Beginner)",
      D: "Rarely (Beginner)",
    },
  },
  {
    id: 4,
    question: "How do you stay updated with new technologies?",
    options: {
      A: "Regularly follow tech news (Advanced)",
      B: "Occasionally read articles (Intermediate)",
      C: "Rarely keep up (Beginner)",
      D: "Attend conferences/workshops (Advanced)",
      E: "Never (Beginner)",
    },
  },
  {
    id: 5,
    question:
      "How comfortable are you with version control systems (e.g., Git)?",
    options: {
      A: "Very comfortable (Advanced)",
      B: "Somewhat comfortable (Intermediate)",
      C: "Not comfortable (Beginner)",
      D: "Never used (Beginner)",
      E: "Use it daily (Advanced)",
    },
  },
];
