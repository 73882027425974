import Header from "./top/header.jsx";
import Card from "./middle/card.jsx";
import Types from "./types/types.jsx";
import Lower from "./lower/lower.jsx";
import End from "./end/end.jsx";
import Preend from "./preend/preend.jsx";
import Navbar from "../commonComponents/navbar/Navbar.jsx";
import Footer from "../commonComponents/footer/Footer.jsx";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NewCard from "./middle/NewCard.jsx";
import dataNew from "../../global/projects.json";

const ProjectPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const targetFeaturedProjects = useRef(null);
  const { project_type } = useParams();
  // console.log(project_type);

  let dataToDisplay = dataNew.filter((item) => {
    // console.log(item.id);
    // console.log(Number(interest_type));
    return item.category === project_type;
  });
  console.log(dataToDisplay);

  return (
    <>
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Projects" />
        <meta name="keywords" content="projects" />
      </Helmet>

      <Box
        sx={{
          backgroundColor: "#F3F4FD",
        }}
      >
        <Navbar />
        <Header targetFeaturedProjects={targetFeaturedProjects} />
        {/* if no data then show this */}
        {project_type ? <NewCard dataToDisplay={dataToDisplay} /> : <Card />}
        <Types />
        <Lower />
        <Preend />
        <End />
        <Footer />
      </Box>
    </>
  );
};

export default ProjectPage;
