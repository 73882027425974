import Tableau from "./Tableau.svg";
import PowerBI from "./PowerBI.svg";
import Matplotlib from "./Matplotlib.svg";
import Seaborn from "./Seaborn.svg";
import SQL from "./SQL.svg";
import Python from "./Python.svg";
import Pandas from "./Pandas.svg";
import NumPy from "./NumPy.svg";
import Scikitlearn from "./ScikitLearn.svg";
import TensorFlow from "./TensorFlow.svg";
import powerquery from "./powerquery.svg";
import excel from "./excel.svg";
import lookerStudio from "./lookerstudio.svg";
import HTML from "./HTML.svg";
import CSS from "./CSS.svg";
import JavaScript from "./JavaScript.svg";
import reactImage from "./reactImage.svg";
import nodejs from "./nodejs.svg";
import mongoDb from "./mongoDb.svg";
import git from "./git.svg";
import mlFlow from "./mlflow.svg";
import kubeflow from "./Kubeflow-01.svg";
import kubernates from "./kubernetes-icon.svg";
import docker from "./Docker.svg";
import apacheAirflow from "./apacheAirflow-removebg-preview.png";
import seldon from "./seldon-removebg-preview.png";
import dvc from "./dvc-removebg-preview.png";
import prometheus from "./prometheus-removebg-preview.png";
import grafana from "./Grafana-removebg-preview.png";
import tensorflowExtended from "./tensorFLowExtended-removebg-preview.png";
import AWSEC2 from "./AWSEC2.svg";
import AWSLambda from "./Lambda.svg";
import AWSS3 from "./AWSS3.svg";
import AWSCloudFormation from "./CloudFormation.svg";
import Ansible from "./ansible.svg";
import Jenkins from "./jenkins.svg";
import Chef from "./chef.svg";
import AWSECS from "./AWSEC2.svg";

const ToolsImages = {
  Tableau: Tableau,
  "Power BI": PowerBI,
  Matplotlib: Matplotlib,
  Seaborn: Seaborn,
  SQL: SQL,
  Python: Python,
  Pandas: Pandas,
  NumPy: NumPy,
  "Scikit-learn": Scikitlearn,
  TensorFlow: TensorFlow,
  "Looker Studio": lookerStudio,
  "Power Query": powerquery,
  Excel: excel,
  HTML: HTML,
  CSS: CSS,
  JavaScript: JavaScript,
  React: reactImage,
  "Node.js": nodejs,
  MongoDB: mongoDb,
  Git: git,
  Docker: docker,
  Kubernetes: kubernates,
  Kubeflow: kubeflow,
  MLflow: mlFlow,
  "TensorFlow Extended": tensorflowExtended,
  "Apache Airflow": apacheAirflow,
  Seldon: seldon,
  DVC: dvc,
  Prometheus: prometheus,
  Grafana: grafana,
  AWSEC2: AWSEC2,
  AWSS3: AWSS3,
  "Cloud Formation": AWSCloudFormation,
  Ansible: Ansible,
  Chef: Chef,
  Jenkins: Jenkins,
  AWSECS: AWSECS,
  AWSLambda: AWSLambda,
};

export default ToolsImages;
