import "./questions.scss";
import React, { useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import Logo from "../../../assets/images/skillAssessment/coursevita-logo-asses.svg";
import Tick from "../../../assets/images/skillAssessment/tickIcon-asses.svg";
import Dot from "../../../assets/images/skillAssessment/circleIcon-asses.svg";
import TickMark from "../../../assets/images/skillAssessment/tickMark.svg";
import Clock from "../../../assets/images/skillAssessment/clock.svg";
import Medal from "../../../assets/images/skillAssessment/medal.svg";
import { useState } from "react";
import VerticalSlider from "./SubComponent/VerticalSlider";
import IMG1 from "../../../assets/images/skillAssessment/card1Img.png";
import IMG2 from "../../../assets/images/skillAssessment/card2Img.png";
import Calendar from "../../../assets/images/skillAssessment/calendar.svg";
import * as question from "./AllQuestion.js";

const Questions = ({ setIsLanding }) => {
  const [questionList, setQuestionList] = useState(question.personaAssesment);
  const [questionNo, setQuestionNo] = useState(1);
  const [currentSection, setCurrentSection] = useState(0);
  const [selectedAns, setSelectedAns] = useState([
    ...Array(questionList.length),
  ]);
  const [isResult, setIsResult] = useState(false);
  const [isResult2, setIsResult2] = useState(false);
  const [message, setMessage] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedCount2, setSelectedCount2] = useState(0);

  useEffect(() => {
    if (questionList.length === 9)
      setSelectedCount(
        selectedAns.filter((item) => item !== undefined && item !== null).length
      );
    else
      setSelectedCount2(
        selectedAns.filter((item) => item !== undefined && item !== null).length
      );
  }, [selectedAns]);

  // console.log(isResult, isResult2);
  //-----------------------------------------//

  const targetRef = useRef(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    startX.current = e.pageX - targetRef.current.offsetLeft;
    scrollLeft.current = targetRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!e.buttons) return;
    const x = e.pageX - targetRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    targetRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
    scrollLeft.current = targetRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    const x = e.touches[0].clientX;
    const walk = (x - startX.current) * 2;
    targetRef.current.scrollLeft = scrollLeft.current - walk;
  };

  //------------------------------------------//

  return (
    <Box className="topWrapper-landing-asses">
      <Box className="logo-landing-asses" component="img" src={Logo}></Box>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Box className="progressBar-question-asses">
            <Box
              width="30%"
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              flex="1"
            >
              <Box
                position="relative"
                zIndex="1"
                component="img"
                src={Tick}
              ></Box>{" "}
              <Typography
                sx={{
                  color: "white",
                  textTransform: "none",
                  fontSize: { lg: "16px", md: "16px", sm: "12px", xs: "12px" },
                  fontFamily: "Outfit",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Persona Assessment
              </Typography>
              <Box className="progressBar-1-asses-unfilled"></Box>
              <Box
                style={{
                  "--selected-count": selectedCount,
                }}
                className="progressBar-1-asses-filled"
              ></Box>
            </Box>
            <Box
              width="30%"
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              flex="1"
            >
              <Box
                position="relative"
                zIndex="1"
                component="img"
                src={isResult || questionList.length === 5 ? Tick : Dot}
              ></Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { lg: "16px", md: "16px", sm: "12px", xs: "12px" },
                  fontFamily: "Outfit",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Skill Assessment
              </Typography>
              <Box className="progressBar-2-asses-unfilled"></Box>
              <Box
                style={{ "--selected-count": selectedCount2 }}
                className="progressBar-2-asses-filled"
              ></Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              flex="1"
            >
              <Box
                zIndex="2"
                component="img"
                src={isResult2 ? Tick : Dot}
              ></Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: { lg: "16px", md: "16px", sm: "12px", xs: "12px" },
                  fontFamily: "Outfit",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Results
              </Typography>
            </Box>
          </Box>
          <Box className="contentBox-question-asses">
            {!isResult &&
              !isResult2 &&
              questionList
                .filter((item) => item.id === questionNo)
                .map((item, index) => {
                  return (
                    <Box key={index}>
                      <Typography className="text-question-asses">
                        {item.id}
                        {". "}
                        {item.question}
                      </Typography>
                      {message && (
                        <Box>
                          <Typography className="unfilled-message-question">
                            Oops! Please make a selection
                          </Typography>
                        </Box>
                      )}
                      {Object.entries(item.options).map(([key, value]) => {
                        return (
                          <Box
                            onClick={() => {
                              const tempArray = [...selectedAns];
                              tempArray[item.id - 1] =
                                key.charCodeAt(0) - "A".charCodeAt(0);
                              setSelectedAns(tempArray);
                              setMessage(false);
                            }}
                            className="gradient-border-question-asses"
                          >
                            <Box className={`question-asses`}>
                              <Box className="box-asses">{key}</Box>
                              <Typography className="questionText">
                                {" "}
                                {value}
                              </Typography>
                              <Box
                                className={`${
                                  selectedAns[item.id - 1] ===
                                  key.charCodeAt(0) - "A".charCodeAt(0)
                                    ? "overlay-question-asses"
                                    : ""
                                }`}
                              ></Box>
                              <Box
                                position="absolute"
                                right="10px"
                                component="img"
                                src={TickMark}
                                display="none"
                                className={`${
                                  selectedAns[item.id - 1] ===
                                  key.charCodeAt(0) - "A".charCodeAt(0)
                                    ? "tick-question-asses"
                                    : ""
                                }`}
                              ></Box>
                            </Box>
                          </Box>
                        );
                      })}
                      <VerticalSlider
                        currentSection={currentSection}
                        setCurrentSection={setCurrentSection}
                        setQuestionNo={setQuestionNo}
                        totalQuestion={questionList.length}
                      />
                      <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        gap="15px"
                        paddingRight="50px"
                        paddingLeft="50px"
                      >
                        <Button
                          onClick={() => {
                            if (questionList.length === 9) {
                              if (questionNo === 1) {
                                setIsLanding(true);
                              } else {
                                setQuestionNo(questionNo - 1);
                                setCurrentSection(currentSection - 1);
                              }
                            } else {
                              if (questionNo === 1) {
                                setIsResult(true);
                              } else {
                                setQuestionNo(questionNo - 1);
                                setCurrentSection(currentSection - 1);
                              }
                            }
                          }}
                          className="prevBtn-question-asses"
                        >
                          Previous
                        </Button>
                        <Button
                          onClick={() => {
                            if (questionList.length === 9) {
                              if (questionNo === 9) {
                                if (selectedCount === 9) setIsResult(true);
                                else setMessage(true);
                              } else {
                                if (selectedCount >= item.id) {
                                  setQuestionNo(questionNo + 1);
                                  setCurrentSection(currentSection + 1);
                                } else setMessage(true);
                              }
                            } else {
                              if (questionNo === 5) {
                                if (selectedCount2 === 5) setIsResult2(true);
                                else setMessage(true);
                              } else {
                                if (selectedCount2 >= item.id) {
                                  setQuestionNo(questionNo + 1);
                                  setCurrentSection(currentSection + 1);
                                } else setMessage(true);
                              }
                            }
                          }}
                          className="nextBtn-question-asses"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  );
                })}

            {isResult && !isResult2 && (
              <Box
                display="flex"
                flexDirection="column"
                padding="20px 30px"
                justifyContent="center"
                alignItems="center"
                height="100%"
                gap="20px"
              >
                <Typography className="text-1-result1">
                  Awesome! You’re a Technical Expert! 🚀
                </Typography>
                <Typography className="text-2-result1">
                  Based on your responses, we've identified your persona. Let's
                  find out your skill level to recommend the perfect courses for
                  you."
                </Typography>
                <Button
                  onClick={() => {
                    setIsResult(false);
                    setQuestionList(question.technicalExpert);
                    setQuestionNo(1);
                    setSelectedAns([...Array(questionList.length)]);
                    setCurrentSection(0);
                  }}
                  className="btn-result1"
                >
                  Begin Skill Assessment
                </Button>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="5px"
                >
                  <Box component="img" src={Clock}></Box>
                  <Typography className="text-3-result1">
                    Takes 5 min
                  </Typography>
                </Box>
              </Box>
            )}
            {!isResult && isResult2 && (
              <Box position="relative">
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="15px"
                  gap="5px"
                >
                  <Box
                    sx={{ width: { md: "30px", sm: "30px", xs: "30px" } }}
                    component="img"
                    src={Medal}
                  ></Box>
                  <Typography className="text-1-result2">
                    Skill Report
                  </Typography>
                </Box>
                <Box
                  marginTop="15px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {[
                    { skill: "Communication Skills", level: 100 },
                    { skill: "Technical Knowledge", level: 80 },
                    { skill: "Analytical Thinking", level: 60 },
                  ].map((item, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex "
                        justifyContent="space-between"
                        alignItems="center"
                        marginBottom="20px"
                        sx={{
                          width: {
                            lg: "510px",
                            md: "510px",
                            sm: "342px",
                            xs: "342px",
                          },
                        }}
                      >
                        <Typography className="text-2-result2">
                          {item.skill}
                        </Typography>
                        <Box
                          position="absolute"
                          left="55%"
                          sx={{
                            transform: {
                              lg: "translate(-50%)",
                              md: "translate(-40%)",
                              sm: "translate(-40%)",
                              xs: "translate(-18%)",
                            },
                          }}
                        >
                          <Box className="progressBar-finalResult-unfilled"></Box>
                          <Box
                            style={{ "--percentage": item.level }}
                            className="progressBar-finalResult-filled"
                          ></Box>
                        </Box>
                        <Typography className="text-3-result2">
                          {item.level}%
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Typography className="text-4-result2">
                  You are at{" "}
                  <span style={{ color: "#BD89FF", fontWeight: "600" }}>
                    Advanced Level!
                  </span>
                </Typography>
                <Typography className="text-5-result2">
                  Based on your answers, these are the Perfect courses Blend
                  with your skills
                </Typography>
                <Box ref={targetRef} className="slider-result2">
                  <Box
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    className="innerSlider-result2"
                  >
                    {[1, 2, 3, 4].map((item, index) => (
                      <Box key={index} className="card-wrapper-result2">
                        <Typography className="text-6-result2">
                          100% Placement assistance
                        </Typography>
                        <Box display="flex" marginTop="5px" gap="5px">
                          <Box component="img" src={IMG1}></Box>
                          <Box>
                            <Typography className="text-7-result2">
                              Mastering Full-Stack Development
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="start"
                              alignItems="center"
                              gap="5px"
                            >
                              <Box component="img" src={Calendar}></Box>
                              <Typography className="text-8-result2">
                                6 months
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <Button className="btn-result2">Know more</Button>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Typography className="text-contentBox-landing-asses-5">
        Powered by{" "}
        <span style={{ fontWeight: "300", fontFamily: "Poppins" }}>
          CourseVita
        </span>
      </Typography>
    </Box>
  );
};

export default Questions;
